<template>
  <div class="subprestation-names">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.OscarSubTaskName"
        :create="openCreateModal"
      >
        <h1 class="title">Sous-tâches OSCAR</h1>
      </page-header>

      <datatable
        ref="subTaskNamesList"
        :fetch="$Api.OscarSubTask.fetchOscarSubTaskNames"
        :enableDownload="true"
        :entity="$EntitiesName.OscarSubTaskName"
      >
        <div slot="taskName" slot-scope="{ item }" title="Nom tâche" :sortable="true">{{ item.taskName }}</div>
        <div slot="subTaskName" slot-scope="{ item }" title="Nom sous-tâche" :sortable="true">{{ item.subTaskName }}</div>
      </datatable>
    </div>

    <modal
      ref="createOscarSubTaskNameModal"
      @create="createSubTaskName"
      title="Création d'un nom de sous-tâche"
    >
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="form">
          <text-field
            label="Nom tâche"
            v-model="newSubTaskName.taskName"
            :inline="false"
            :edit="true"
            required
          />
          <text-field
            label="Nom sous-tâche"
            v-model="newSubTaskName.subtaskName"
            :inline="false"
            :edit="true"
            required
          />
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "oscar-subtask-names",
  data() {
    return {
      newSubTaskName: {},
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createOscarSubTaskNameModal.open();
    },
    createSubTaskName() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post("/oscar-subtask-names", this.newSubTaskName).then((response) => {
          if (response.data.success) {
            this.newSubTaskName = {};
            this.$refs.subTaskNamesList.refresh();
            this.$refs.createOscarSubTaskNameModal.close();
            this.$router.push({
              name: "OscarSubTaskName",
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>